import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import NavbarSec from "../Shared/NavbarSec";
import Footer from "../Shared/Footer";
import cloudIcon from "../Img/cloudIcon.png";

export default class Pricing extends Component {
  componentDidMount() {
    AOS.init({ duration: 1000 });
  }

  render() {
    return (
      <div>
        <NavbarSec />

        <Container fluid className="pricingPrt" id="dashPricing">
          <Row className="PricingSec">
            <Col xs={12}>
              <h2>
                <img src={cloudIcon} width="50px" alt="cloudIcon" />
                &nbsp;&nbsp;At Your Desk
              </h2>
              <p>Installer configuration & management tool in the cloud</p>
            </Col>

            <Col
              xs={10}
              sm={10}
              md={{ span: 10, offset: 1 }}
              lg={{ span: 10, offset: 1 }}
              xl={{ span: 10, offset: 1 }}
              xxl={{ span: 10, offset: 1 }}
            >
              <Card className="text-center" data-aos="zoom-in-up">
                <Card.Header>
                  <h3>
                    $10<small className="text-muted">per month</small>
                  </h3>
                  <h4>
                    <div className="pricingSub">Basic Plan</div>
                  </h4>
                  <p>
                    Sign up & stay in touch with your clients home or SMB from
                    almost anywhere.
                  </p>
                </Card.Header>

                <Card.Body>
                  <Container>
                    <Row>
                      <Col>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;6 Installers
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Unlimites Sites
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Secure CID
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;End to End Ecnryption
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Two Factor Authentication
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Arm and Disarm
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                      <Col>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Event Notifications
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Output Control
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Real Time Zone Status
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Zone Bypassing
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Event Memory
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;User Management
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                      <Col>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Custom Function Futtons
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Cloud API
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Remote Programming
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Remote User Config
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Access Control Config
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Geo Fencing
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>

                <Card.Footer className="text-muted">
                  <Link
                    to={{ pathname: "https://account.elitecloud.co.nz/" }}
                    target="_blank"
                  >
                    Start Free Trial For 30 Days
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container className="appPriciing">
          <Row className="PricingSec" id="appPricing">
            <Col xs={12}>
              <h2>
                <img src={cloudIcon} width="50px" alt="cloudIcon" />
                &nbsp;&nbsp;In Your Hand
              </h2>
              <p>User security & control smartphone app</p>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
              <Card className="text-center" data-aos="zoom-in-down">
                <Card.Header>
                  <h3>
                    $1.49<small className="text-muted">per month</small>
                  </h3>
                  <h4>
                    <div className="pricingSub">Home</div>
                  </h4>
                  <p>Signup for Standard home with 5 Users</p>
                </Card.Header>

                <Card.Body>
                  <Container>
                    <Row>
                      <Col>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;5 Users
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;1 Site
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Arm & Disarm
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Output Control
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Real Time Zone Status
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Sensor Snooze Function
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;History
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Notifications
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>

                <Card.Footer className="text-muted">
                  <Link
                    to={{ pathname: "https://account.elitecloud.co.nz/" }}
                    target="_blank"
                  >
                    Start Free Trial For 30 Days
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
              <Card className="text-center" data-aos="zoom-in-down">
                <Card.Header>
                  <h3>
                    $2.49<small className="text-muted">per month</small>
                  </h3>
                  <h4>
                    <div className="pricingSub">Family</div>
                  </h4>
                  <p>Signup for Family home with 10 Users</p>
                </Card.Header>

                <Card.Body>
                  <Container>
                    <Row>
                      <Col>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;10 Users
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;4 Sites
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Arm & Disarm
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Outpt Control
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Real Time Zone Status
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Sensor Snooze Function
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;History
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Notifications
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>

                <Card.Footer className="text-muted">
                  <Link
                    to={{ pathname: "https://account.elitecloud.co.nz/" }}
                    target="_blank"
                  >
                    Start Free Trial For 30 Days
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
              <Card className="text-center" data-aos="zoom-in-down">
                <Card.Header>
                  <h3>
                    $20<small className="text-muted">per month</small>
                  </h3>
                  <h4>
                    <div className="pricingSub">Enterprise</div>
                  </h4>
                  <p>Signup for Enterprise with unlimited Users</p>
                </Card.Header>

                <Card.Body>
                  <Container>
                    <Row>
                      <Col>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Unlimited Users
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;1 Site
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Arm & Disarm
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Output Control
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Real Time Zone Status
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Sensor Snooze Function
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;History
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Notifications
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>

                <Card.Footer className="text-muted">
                  <Link
                    to={{ pathname: "https://account.elitecloud.co.nz/" }}
                    target="_blank"
                  >
                    Start Free Trial For 30 Days
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}
