import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  ListGroup,
  Image,
  Nav,
  Tab,
} from "react-bootstrap";
import homescreen from "../Img/homescreen.png";
// import UserImg from "../Img/user.jpg";
import User2 from "../Img/Dave.jpg";
import User1 from "../Img/Andre.jpg";
//import Banner from "../Img/Banner.png";
import cloudIcon from "../Img/cloudIcon.png";
import { HashLink } from "react-router-hash-link";
import "../App.css";
import NavbarSec from "../Shared/NavbarSec";
import Footer from "../Shared/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faHouseBuilding,
  faUserHelmetSafety,
  faBuilding,
} from "@fortawesome/pro-light-svg-icons";
import {
  faQuoteLeft,
  faCheck,
  faMobileAlt,
  faTools,
  faHome,
  faCloud,
  faEye,
  faBell,
  // faLink,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import ScrollIndicator from "../Component/ScrollToTop";
import AppSlider from "./AppSlider";
import DashboardSlider from "./DashboardSlider";
//import EmailPops from "./EmailPops.js";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
import classnames from "classnames";

export default function Home({ message }) {
  useEffect(() => {
    AOS.init({ duration: 1500, once: true });
  }, []);

  const [isOpen, setOpen] = useState(false);
  return (
    <div>
      <Container className={classnames({ "show-server-status": message })}>
        <NavbarSec message={message} />
      </Container>
      <div
        className={classnames({
          "show-server-status": message !== null,
        })}
      >
        <Container id="home">
          <Row>
            <Col xs={12} sm={5} md={5} lg={5} xl={5} xxl={5}>
              <Row>
                <Col xs={12}>
                  <h4 className="wlcmnt" data-aos="zoom-out-right">
                    Welcome To EliteCloud
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <h1 className="landhd" data-aos="zoom-out-right">
                    Intuitive security & control.
                  </h1>
                </Col>{" "}
              </Row>
              <Row>
                <Col xs={12}>
                  <h3 className="landNte" data-aos="zoom-out-right">
                    Move your security to the cloud & stay in touch with your
                    home or business. Our EliteCloud smartphone app & management
                    dashboard puts you in control, anytime & from almost
                    anywhere.
                  </h3>
                </Col>
              </Row>
              <Row>
                {" "}
                <Col xs={12}>
                  <div className="landBtn">
                    <Link
                      to={{
                        pathname: "https://account.elitecloud.co.nz/register/",
                      }}
                      target="_blank"
                    >
                      {" "}
                      <Button variant="primary" size="lg">
                        Sign Up
                      </Button>
                    </Link>
                    &nbsp;&nbsp;
                    <HashLink
                      style={{ behavior: "smooth" }}
                      to="/#fontawesmeCntrFld"
                    >
                      <Button className="btn-learnmore" size="lg">
                        Learn More
                      </Button>
                    </HashLink>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={7} md={7} lg={7} xl={7} xxl={7}>
              <Image
                width={800}
                className="landImg"
                src={homescreen}
                data-aos="flip-right"
                fluid
              />
            </Col>
          </Row>
        </Container>
        <Container fluid className="fontawesmeCntrFld" id="fontawesmeCntrFld">
          <Row className="fontfeatrs">
            <Col>
              <h2>Features</h2>

              <Container className="fontContnr">
                <Row>
                  <Col className="fontfeatrsSec" xs={6} md={3}>
                    <div data-aos="flip-left" className="featrBtn" size="sm">
                      <FontAwesomeIcon icon={faMobileAlt} />
                    </div>
                    <h6>In Your Hand</h6>
                    <p>
                      Intuitive security & control
                      <br /> of your home or business via almost any smart
                      device.
                    </p>
                  </Col>
                  <Col className="fontfeatrsSec" xs={6} md={3}>
                    <div data-aos="flip-left" className="featrBtn" size="sm">
                      <FontAwesomeIcon icon={faTools} />
                    </div>
                    <h6>At Your Desk</h6>
                    <p>
                      System analysis & <br />
                      configuration, any time &<br /> from almost anywhere.
                      <br />
                      Coming soon
                    </p>
                  </Col>
                  <Col className="fontfeatrsSec" xs={6} md={3}>
                    <div data-aos="flip-left" className="featrBtn" size="sm">
                      <FontAwesomeIcon icon={faHome} />
                    </div>
                    <h6>Asset Protection</h6>
                    <p>
                      Intruder & fire security
                      <br /> for your home. Always there
                      <br /> when you can't be.
                    </p>
                  </Col>
                  <Col className="fontfeatrsSec" xs={6} md={3}>
                    <div data-aos="flip-left" className="featrBtn" size="sm">
                      <FontAwesomeIcon icon={faCloud} />
                    </div>
                    <h6>Cloud</h6>
                    <p>
                      Bank level encryption
                      <br /> from control panel, <br />
                      to cloud, to you.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="fontfeatrsSec" xs={6} md={4}>
                    <div data-aos="flip-right" className="featrBtn" size="sm">
                      <FontAwesomeIcon icon={faEye} />
                    </div>
                    <h6>Eyes on the Asset</h6>
                    <p>
                      Choose from a range of monitoring solutions including
                      smartphone
                      <br /> & guard response.
                    </p>
                  </Col>
                  <Col className="fontfeatrsSec" xs={6} md={4}>
                    <div data-aos="flip-right" className="featrBtn" size="sm">
                      <FontAwesomeIcon icon={faBell} />
                    </div>
                    <h6>Choose Your Alert</h6>
                    <p>
                      Select from more than 80 individual notification options
                      within the EliteCloud app.
                    </p>
                  </Col>
                  {/* <Col className="fontfeatrsSec">
                  <div data-aos="flip-right" className="featrBtn" size="sm">
                    <FontAwesomeIcon icon={faLink} />
                  </div>
                  <h6>Developer API</h6>
                  <p>
                    Integrate
                    <br />
                    your service with EliteCloud.
                    <br />
                    Coming soon
                  </p>
                </Col> */}
                  <Col className="fontfeatrsSec" xs={12} md={4}>
                    <div data-aos="flip-right" className="featrBtn" size="sm">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    <h6>Trust</h6>
                    <p>
                      5 year EliteControl warranty
                      <br />& with 30 years experience in
                      <br />
                      security manufacturing.
                    </p>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Container fluid className="sliderSec" id="smartApp">
          <Row>
            <Col>
              <AppSlider />
            </Col>
          </Row>
        </Container>
        <Container
          fluid
          className="videobanner"
          // style={{
          //   backgroundImage: "url(" + banner + ")",
          //   height:'600px',width:'100%'
          // }}
        >
          <Container>
            <Row>
              <Col xs={12} md={6}>
                {" "}
                <h1 data-aos="zoom-out-right">
                  Security & Control from your smart device
                </h1>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <h4>New Zealand designed & manufactured for over 30 years</h4>
              </Col>
            </Row>
            <Col xs={12} md={4}>
              <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="qAD7qpxsMTI"
                onClose={() => setOpen(false)}
              />
              <h4 className="videoplay" onClick={() => setOpen(true)}>
                <span style={{ cursor: "pointer", textAlign: "justify" }}>
                  Watch the Video
                </span>
                <FontAwesomeIcon
                  icon={faPlayCircle}
                  style={{ margin: "10px 0px 0px 20px" }}
                  onClick={() => setOpen(true)}
                />
              </h4>
            </Col>
          </Container>
        </Container>
        <Container fluid className="showonMObile">
          <Row></Row>
        </Container>
        <Container fluid className="sliderDashSec" id="dashboard">
          <Row>
            <Col>
              <DashboardSlider />
            </Col>
          </Row>
        </Container>

        <Container fluid className="appPriciing" id="pricing">
          <Container>
            <Row className="PricingSec">
              <Col xs={12} className="SubscriptionTabs">
                <h2>
                  <img src={cloudIcon} width="50px" alt="icon" />
                  &nbsp;&nbsp;In Your Hand
                </h2>
                <p>Select the EliteCloud smartphone plan that suits you.</p>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col>
                      <Nav variant="pills" className="flex-container">
                        <Nav.Item className="flex-item">
                          <Nav.Link eventKey="first">Monthly</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="flex-item">
                          <Nav.Link eventKey="second">Annually</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <Container>
                          <Row>
                            <Col xs={12} sm={12} md={6} lg={3} xl={3} xxl={3}>
                              <Card
                                className="text-center"
                                data-aos="zoom-out-down"
                              >
                                <Card.Header>
                                  <h4 className="SubscribeIcon">
                                    <FontAwesomeIcon icon={faHouse} />
                                  </h4>
                                  <p>
                                    <b>Home Plan</b>
                                  </p>
                                  <p>
                                    Sign up to home with 1 site & up to 5 users
                                  </p>
                                  <h3>
                                    $1.49
                                    <small className="text-muted">
                                      per month
                                    </small>
                                  </h3>
                                </Card.Header>
                                <Card.Body>
                                  <Container>
                                    <Row>
                                      <Col>
                                        <ListGroup variant="flush">
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;1 Site
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;5 Users
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Arm & Disarm
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Output Control
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Real Time Zone
                                            Status
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Sensor Snooze
                                            Function
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;History
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Push notifications
                                          </ListGroup.Item>
                                        </ListGroup>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Card.Body>

                                <Link
                                  to={{
                                    pathname:
                                      "https://account.elitecloud.co.nz/register/",
                                  }}
                                  target="_blank"
                                >
                                  <Card.Footer className="text-muted">
                                    Start Free Trial For 30 Days
                                  </Card.Footer>
                                </Link>
                              </Card>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={3} xl={3} xxl={3}>
                              <Card
                                className="text-center"
                                data-aos="zoom-out-down"
                              >
                                <Card.Header>
                                  <h4 className="SubscribeIcon">
                                    <FontAwesomeIcon icon={faHouseBuilding} />
                                  </h4>
                                  <p>
                                    <b>Multi Plan</b>
                                  </p>
                                  <p>
                                    Sign up to multi with 4 sites & up to 10
                                    users
                                  </p>
                                  <h3>
                                    $2.49
                                    <small className="text-muted">
                                      per month
                                    </small>
                                  </h3>
                                </Card.Header>
                                <Card.Body>
                                  <Container>
                                    <Row>
                                      <Col>
                                        <ListGroup variant="flush">
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;4 Sites
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;10 Users
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Arm & Disarm
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Output Control
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Real Time Zone
                                            Status
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Sensor Snooze
                                            Function
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;History
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Push notifications
                                          </ListGroup.Item>
                                        </ListGroup>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Card.Body>
                                <Link
                                  to={{
                                    pathname:
                                      "https://account.elitecloud.co.nz/register/",
                                  }}
                                  target="_blank"
                                >
                                  <Card.Footer className="text-muted">
                                    Start Free Trial For 30 Days
                                  </Card.Footer>
                                </Link>
                              </Card>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={3} xl={3} xxl={3}>
                              <Card
                                className="text-center"
                                data-aos="zoom-out-down"
                              >
                                <Card.Header>
                                  <h4 className="SubscribeIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </h4>
                                  <p>
                                    <b>Enterprise Plan</b>
                                  </p>
                                  <p>
                                    Sign up to enterprise with 10 sites,up to
                                    100 users & management dashboard
                                  </p>
                                  <h3>
                                    $10
                                    <small className="text-muted">
                                      per month
                                    </small>
                                  </h3>
                                </Card.Header>
                                <Card.Body>
                                  <Container>
                                    <Row>
                                      <Col>
                                        <ListGroup variant="flush">
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;10 Sites & up to
                                            100 Users
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;User management
                                            dashboard
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Arm & Disarm
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Output Control
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Real Time Zone
                                            Status
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Sensor Snooze
                                            Function
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;History
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Push notifications
                                          </ListGroup.Item>
                                        </ListGroup>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Card.Body>

                                <Link
                                  to={{
                                    pathname:
                                      "https://account.elitecloud.co.nz/register/",
                                  }}
                                  target="_blank"
                                >
                                  <Card.Footer className="text-muted">
                                    Start Free Trial For 30 Days
                                  </Card.Footer>
                                </Link>
                              </Card>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={3} xl={3} xxl={3}>
                              <Card
                                className="text-center"
                                data-aos="zoom-out-down"
                              >
                                <Card.Header>
                                  <h4 className="SubscribeIcon">
                                    <FontAwesomeIcon
                                      icon={faUserHelmetSafety}
                                    />
                                  </h4>
                                  <p>
                                    <b>Installer Plan</b>
                                  </p>
                                  <p>
                                    Sign up to installers management dashboard &
                                    app plan
                                  </p>
                                  <h3>
                                    $10
                                    <small className="text-muted">
                                      per month
                                    </small>
                                  </h3>
                                </Card.Header>
                                <Card.Body>
                                  <Container>
                                    <Row>
                                      <Col>
                                        <ListGroup variant="flush">
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Over the air
                                            programming & backup
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Over the air
                                            updates
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Unlimited
                                            dashboard sites
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;4 App Sites
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;10 App Users
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Alarm Status &
                                            Control
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Comprehensive
                                            System History
                                          </ListGroup.Item>
                                          {/* <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;History
                          </ListGroup.Item> */}
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Notifications
                                          </ListGroup.Item>
                                        </ListGroup>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Card.Body>
                                <Card.Footer className="text-muted">
                                  Coming soon
                                </Card.Footer>
                              </Card>
                            </Col>
                          </Row>
                        </Container>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Container>
                          <Row>
                            <Col xs={12} sm={12} md={6} lg={3} xl={3} xxl={3}>
                              <Card
                                className="text-center"
                                data-aos="zoom-out-down"
                              >
                                <Card.Header>
                                  <h4 className="SubscribeIcon">
                                    <FontAwesomeIcon icon={faHouse} />
                                  </h4>
                                  <p>
                                    <b>Home Plan</b>
                                  </p>
                                  <p>
                                    Sign up to home with 1 site & up to 5 users
                                  </p>
                                  <h3>
                                    $17.49
                                    <small className="text-muted">
                                      {" "}
                                      per year
                                    </small>
                                  </h3>
                                </Card.Header>
                                <Card.Body>
                                  <Container>
                                    <Row>
                                      <Col>
                                        <ListGroup variant="flush">
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;1 Site
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;5 Users
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Arm & Disarm
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Output Control
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Real Time Zone
                                            Status
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Sensor Snooze
                                            Function
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;History
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Push notifications
                                          </ListGroup.Item>
                                        </ListGroup>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Card.Body>

                                <Link
                                  to={{
                                    pathname:
                                      "https://account.elitecloud.co.nz/register/",
                                  }}
                                  target="_blank"
                                >
                                  <Card.Footer className="text-muted">
                                    Start Free Trial For 30 Days
                                  </Card.Footer>
                                </Link>
                              </Card>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={3} xl={3} xxl={3}>
                              <Card
                                className="text-center"
                                data-aos="zoom-out-down"
                              >
                                <Card.Header>
                                  <h4 className="SubscribeIcon">
                                    <FontAwesomeIcon icon={faHouseBuilding} />
                                  </h4>
                                  <p>
                                    <b>Multi Plan</b>
                                  </p>
                                  <p>
                                    Sign up to multi with 4 sites & up to 10
                                    users
                                  </p>
                                  <h3>
                                    $29.49
                                    <small className="text-muted">
                                      {" "}
                                      per year
                                    </small>
                                  </h3>
                                </Card.Header>
                                <Card.Body>
                                  <Container>
                                    <Row>
                                      <Col>
                                        <ListGroup variant="flush">
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;4 Sites
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;10 Users
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Arm & Disarm
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Output Control
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Real Time Zone
                                            Status
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Sensor Snooze
                                            Function
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;History
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Push notifications
                                          </ListGroup.Item>
                                        </ListGroup>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Card.Body>
                                <Link
                                  to={{
                                    pathname:
                                      "https://account.elitecloud.co.nz/register/",
                                  }}
                                  target="_blank"
                                >
                                  <Card.Footer className="text-muted">
                                    Start Free Trial For 30 Days
                                  </Card.Footer>
                                </Link>
                              </Card>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={3} xl={3} xxl={3}>
                              <Card
                                className="text-center"
                                data-aos="zoom-out-down"
                              >
                                <Card.Header>
                                  <h4 className="SubscribeIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </h4>
                                  <p>
                                    <b>Enterprise Plan</b>
                                  </p>
                                  <p>
                                    Sign up to enterprise with 10 sites,up to
                                    100 users & management dashboard
                                  </p>
                                  <h3>
                                    $119.99
                                    <small className="text-muted">
                                      {" "}
                                      per year
                                    </small>
                                  </h3>
                                </Card.Header>
                                <Card.Body>
                                  <Container>
                                    <Row>
                                      <Col>
                                        <ListGroup variant="flush">
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;10 Sites & up to
                                            100 Users
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;User management
                                            dashboard
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Arm & Disarm
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Output Control
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Real Time Zone
                                            Status
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Sensor Snooze
                                            Function
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;History
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Push notifications
                                          </ListGroup.Item>
                                        </ListGroup>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Card.Body>

                                <Link
                                  to={{
                                    pathname:
                                      "https://account.elitecloud.co.nz/register/",
                                  }}
                                  target="_blank"
                                >
                                  <Card.Footer className="text-muted">
                                    Start Free Trial For 30 Days
                                  </Card.Footer>
                                </Link>
                              </Card>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={3} xl={3} xxl={3}>
                              <Card
                                className="text-center"
                                data-aos="zoom-out-down"
                              >
                                <Card.Header>
                                  <h4 className="SubscribeIcon">
                                    <FontAwesomeIcon
                                      icon={faUserHelmetSafety}
                                    />
                                  </h4>
                                  <p>
                                    <b>Installer Plan</b>
                                  </p>
                                  <p>
                                    Sign up to installers management dashboard &
                                    app plan
                                  </p>
                                  <h3>
                                    $119.99
                                    <small className="text-muted">
                                      {" "}
                                      per year
                                    </small>
                                  </h3>
                                </Card.Header>
                                <Card.Body>
                                  <Container>
                                    <Row>
                                      <Col>
                                        <ListGroup variant="flush">
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Over the air
                                            programming & backup
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Over the air
                                            updates
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Unlimited
                                            dashboard sites
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;4 App Sites
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;10 App Users
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Alarm Status &
                                            Control
                                          </ListGroup.Item>
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Comprehensive
                                            System History
                                          </ListGroup.Item>
                                          {/* <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;History
                          </ListGroup.Item> */}
                                          <ListGroup.Item>
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp;&nbsp;&nbsp;Notifications
                                          </ListGroup.Item>
                                        </ListGroup>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Card.Body>
                                <Card.Footer className="text-muted">
                                  Coming soon
                                </Card.Footer>
                              </Card>
                            </Col>
                          </Row>
                        </Container>
                      </Tab.Pane>
                    </Tab.Content>
                  </Row>
                </Tab.Container>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className="testimonialRw">
          <Container>
            <Row href="#deets">
              <Col
                xs={12}
                sm={12}
                md={{ span: 4 }}
                lg={{ span: 4 }}
                xl={{ span: 4 }}
                xxl={{ span: 4 }}
              >
                <h6>Please share your feedback</h6>
                <h2>Testimonials</h2>
                <br />
                <p>
                  Send us feedback about your EliteCloud experience. A short
                  testimonial is also welcome if you feel comfortable sharing
                </p>
                <Link
                  to={{
                    pathname: "/feedback",
                  }}
                  target="_blank"
                >
                  <Button variant="primary" className="feedback" size="lg">
                    Send us feedback
                    <br />
                  </Button>
                </Link>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={{ span: 6, offset: 2 }}
                lg={{ span: 6, offset: 2 }}
                xl={{ span: 6, offset: 2 }}
                xxl={{ span: 6, offset: 2 }}
              >
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <Card data-aos="zoom-in-down">
                  <Row>
                    <Col xs={1}>
                      <FontAwesomeIcon icon={faQuoteLeft} />
                    </Col>
                    <Col xs={8}>
                      <Card.Body>
                        <p>
                          The EliteCloud APP covers all bases & more when it
                          comes to home security & control. Additional system
                          status parameters also allow for simple diagnostics of
                          the Elite alarm system, making life easy for both the
                          end user and the installer.
                          <br />
                          <b style={{ fontSize: "12px" }}>David Hudson</b>
                        </p>
                      </Card.Body>
                    </Col>
                    <Col xs={2} className="userImg">
                      <img src={User2} width="60px" alt="UserImg" />
                    </Col>
                  </Row>
                </Card>
                <br />
                <Card data-aos="zoom-in-down">
                  <Row>
                    <Col xs={1}>
                      <FontAwesomeIcon icon={faQuoteLeft} />
                    </Col>
                    <Col xs={8}>
                      <Card.Body>
                        <p>
                          EliteCloud is everything I hoped it would be! It is
                          fast loading, includes customisable 'favourite
                          buttons' & also a range of push notification options.
                          History is simple to use, including custom icons &
                          filtering to easily identify what has happened at your
                          home or business. I am definitely excited to see
                          continuous development to this great service.
                          <br />
                          <b style={{ fontSize: "12px" }}>
                            Andre Appleton Tattersall
                          </b>
                        </p>
                      </Card.Body>
                    </Col>
                    <Col xs={2} className="userImg">
                      <img src={User1} width="60px" alt="UserImg" />
                    </Col>
                  </Row>
                </Card>
                <br />
                {/* <Card data-aos="zoom-in-down">
                <Row>
                  <Col xs={1}>
                    <FontAwesomeIcon icon={faQuoteLeft} />
                  </Col>
                  <Col xs={8}>
                    <Card.Body>Please share your experience.</Card.Body>
                  </Col>
                  <Col xs={2}>
                    <img src={UserImg} width="60px" alt="UserImg" />
                  </Col>
                </Row>
              </Card> */}
                <br />
              </Col>
            </Row>
          </Container>
        </Container>
        <Container>
          <div className="scrollTop">
            <ScrollIndicator />
          </div>
        </Container>

        <Footer />
      </div>
    </div>
  );
}
