import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Component/Home";
import ScrollIndicator from "./Component/ScrollToTop";
import Pricing from "./Component/Pricing";
import AppSlider from "./Component/AppSlider";
import EmailPops from "./Component/EmailPops";
import Feedback from "./Component/Feedback";
import Upgrade from "./Component/Upgrade";
import useServerStatus from "./hooks/useServerStatus";
import BannerMessage from "./Component/BannerMessage";

function App() {
  const { message } = useServerStatus();

  return (
    <>
      {message !== null && <BannerMessage message={message} />}
      <Router>
        <Switch>
          <Route exact path={"/"} render={() => <Home message={message} />} />
          <Route exact path={"/scroll"} component={ScrollIndicator} />
          <Route exact path={"/pricing"} component={Pricing} />
          <Route exact path={"/appslider"} component={AppSlider} />
          <Route exact path={"/mail"} component={EmailPops} />
          <Route
            exact
            path={"/feedback"}
            render={() => <Feedback message={message} />}
          />
          <Route
            exact
            path={"/upgrade"}
            render={() => <Upgrade message={message} />}
          />
        </Switch>
      </Router>
    </>
  );
}

export default App;
